.App {
    text-align: center;
    background-color: #eee;
}

.App section:not(:first-child) {
    margin-left: 5%;
    margin-right: 5%;
}

@media only screen and (max-width: 576px) {
    .App section:not(:first-child) {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 720px) {
    .App section:not(:first-child) {
        margin-left: 8%;
        margin-right: 8%;
    }
}
