.SelectManual .title {
    font-size: 24px;
    margin-top: 15px;
}

.SelectManual .subtitle {
    font-size: 12px;
    margin-top: 15px;
}

.SelectManual > .container {
    width: 85%;
    margin: 0 auto;
    margin-top: 15px;
}

.SelectManual .subcategory-title {
    color: #878787;
    font-size: 16px;
    padding: 0 15px;
    margin: 8px 0;
    text-align: left;
}

@media only screen and (max-width: 576px) {
    .SelectManual > .container {
        width: 100%;
        margin-top: 15px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1140px) {
    .SelectManual > .container {
        width: 90%;
        margin: 0 auto;
        margin-top: 15px;
    }
}
