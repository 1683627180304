.WizardStep {
    cursor: pointer;
}

.Wizard ul {
    display: inline;
    list-style: none;
    padding: 0;
}

.Wizard li {
    display: inline;
    margin: 5px 20px;
    font-size: 14px;
}

.WizardStep.disabled {
    color: #bbb;
}

@media only screen and (max-width: 576px) {
    .Wizard li {
        font-size: 11px;
        margin: 5px 5px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 720px) {
    .Wizard li {
        font-size: 12px;
        margin: 5px 8px;
    }
}
