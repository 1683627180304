.DocumentGroup {
    margin: 15px 15px;
}

.DocumentGroup .container {
    border: 1px solid #e4e4e4;
    padding: 10px 0;
}

.DocumentGroup .file-icon {
    color: #777777;
    font-size: 18px;
    float: left;
    padding: 0 10px;
}

.DocumentGroup .document-title {
    color: #777777;
    float: left;
    margin: 0;
}

.DocumentGroup .document-download {
    color: #777777;
    float: right;
    margin: 0;
    margin-right: 15px;
}

.DocumentGroup button:disabled {
    background-color: #dddddd;
}

.DocumentGroup button:active,
.DocumentGroup button:focus {
    background-color: white;
}

.DocumentGroup .btn-download {
    font-weight: 400;
    cursor: pointer;
    color: #333;
    background-color: #fff;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    margin-right: 3px;
    margin-left: 3px;
    border: 1px solid #ccc;
}

.DocumentGroup .btn-download:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
}

.DocumentGroup .btn-download:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

@media only screen and (max-width: 720px) {
    .DocumentGroup .document-download {
        float: none;
        margin-top: 10px;
        margin-left: 10px;
        text-align: left;
    }
}
