.StepNumber {
    border-radius: 50%;
    display: inline-block;
    color: white;
    background-color: #ddd;
    width: 30px;
    height: 30px;
    line-height: 29px;
}

.StepNumber.active {
    background-color: #2bd579;
}

@media only screen and (max-width: 576px) {
    .StepNumber {
        width: 18px;
        height: 18px;
        line-height: 17px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 720px) {
    .StepNumber {
        width: 25px;
        height: 25px;
        line-height: 24px;
    }
}
