.VehicleTypeButton {
    background-color: #ddd;
    border: 2px solid #ddd;
    cursor: pointer;
    width: 110px;
    text-align: center;
    padding: 10px 30px;
    margin-bottom: 20px;
    border-radius: 15px;
}

.VehicleTypeButton:hover {
    background-color: #eee;
}
