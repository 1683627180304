.SelectVehicle .title {
    font-size: 24px;
    margin-top: 15px;
}

.SelectVehicle .subtitle {
    font-size: 12px;
    margin-top: 15px;
}

.SelectVehicle .vehicle-type-list {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SelectVehicle ul {
    display: inline;
    list-style: none;
}

.SelectVehicle li {
    display: inline;
    margin: 5px 10px;
}
